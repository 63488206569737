// 订单管理

export default {
    path: "/commission",
    name: "Commission",
    component: () =>
        import(
            /* webpackChunkName: "core" */
            "../views/_2_core/core.vue"
        ),
    title: "佣金管理",
    icon: "user",

    children: [
        {
            path: "orderCommission",
            name: "OrderCommission",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/commissionList/commissionList.vue"
                ),
            title: "订单佣金结算",
        },
        {
            path: "orderPerformanceAccountList",
            name: "OrderPerformanceAccountList",
            component: () =>
                import(
                    "../views/_2_core/orderPerformanceAccountList/orderPerformanceAccountList.vue"
                ),
            title: "订单绩效核算",
        },
        {
            path: "orderCommission/detail/:id",
            name: "OrderCommissionDetail",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/commissionDetail/commissionDetail.vue"
                ),
            title: "结佣详情",
            awayMenu: true
        },
        {
            path: "seasonBonusList",
            name: "SeasonBonusList",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/seasonBonusList/seasonBonusList.vue"
                ),
            title: "赛季奖金",
        },
        {
            path: "seasonBonusList/detail/:id",
            name: "SeasonBonusDetail",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/seasonBonusDetail/seasonBonusDetail.vue"
                ),
            title: "订单奖金明细",
            awayMenu: true
        },
        {
            path: "seasonBonusList/detail/detail/:id",
            name: "SeasonBonusDetail",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/seasonBonusDetail/seasonBonusDetail2.vue"
                ),
            title: "奖金记录",
            awayMenu: true
        },
        // 

        {
            path: "empSeasonBonusList",
            name: "empSeasonBonusList",
            component: () =>
                import(
                    "../views/_2_core/empSeasonBonusList/empSeasonBonusList.vue"
                ),
            title: "员工赛季发放奖金",
        },
        {
            path: "empSeasonBonusList/detail/:id",
            name: "empSeasonBonusDetail",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/empSeasonBonusDetail/empSeasonBonusDetail.vue"
                ),
            title: "奖金明细",
            awayMenu: true
        },



        {
            path: "outChannelCommission",
            name: "OutChannelCommission",
            component: () =>
                import(
                    /* webpackChunkName: "core" */
                    "../views/_2_core/outChannelCommission/outChannelCommission.vue"
                ),
            title: "渠道商结佣"
        },
    ],
}